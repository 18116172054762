import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'

import { MarkdownContent } from '../components/Content'

import Layout from '../components/Layout'

const IndexPageTemplate = ({
  title,
  description,
  uberEats,
  deliveroo,
  mainMenuPhoto,
  setMenusPhoto,
  dimSumMenuPhoto,
}) => (
  <div className="index-wrapper">
    <h1 className="top-title">{title}</h1>
    <div className="menu-links">
      <Link
        className="menu-link"
        style={{
          backgroundImage: `url(${mainMenuPhoto.childImageSharp.fixed.src})`,
        }}
        to="/menus/main-menu"
      >
        <p>Main Menu</p>
      </Link>
      <Link
        className="menu-link"
        style={{
          backgroundImage: `url(${setMenusPhoto.childImageSharp.fixed.src})`,
        }}
        to="/menus/set-menus"
      >
        <p>Set Menus</p>
      </Link>
      <Link
        className="menu-link"
        style={{
          backgroundImage: `url(${dimSumMenuPhoto.childImageSharp.fixed.src})`,
        }}
        to="/menus/dim-sum-menu"
      >
        <p>Dim Sum Menu</p>
      </Link>
    </div>
    <div className="about">
      <h1 className="inline-title">{title}</h1>
      <MarkdownContent content={description} />
    </div>
  </div>
)

IndexPageTemplate.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <IndexPageTemplate
        title={frontmatter.title}
        description={frontmatter.description}
        uberEats={data.contact.frontmatter.uberEats}
        deliveroo={data.contact.frontmatter.deliveroo}
        mainMenuPhoto={data.mainMenu.frontmatter.photo}
        setMenusPhoto={data.setMenus.frontmatter.photo}
        dimSumMenuPhoto={data.dimSumMenu.frontmatter.photo}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
    contact: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
    mainMenu: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
    setMenus: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
    dimSumMenu: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        description
      }
    }
    contact: markdownRemark(frontmatter: { title: { eq: "contact" } }) {
      frontmatter {
        uberEats
        deliveroo
      }
    }
    mainMenu: markdownRemark(frontmatter: { title: { eq: "Main Menu" } }) {
      frontmatter {
        photo {
          childImageSharp {
            fixed(width: 400, height: 300) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
    setMenus: markdownRemark(frontmatter: { title: { eq: "Set Menus" } }) {
      frontmatter {
        photo {
          childImageSharp {
            fixed(width: 400, height: 300) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
    dimSumMenu: markdownRemark(
      frontmatter: { dataKey: { eq: "dim-sum-menu" } }
    ) {
      frontmatter {
        photo {
          childImageSharp {
            fixed(width: 400, height: 300) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`
